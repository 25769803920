<template>
    <div class="main-pdding">
        <div class="main-box">
            <editSub title="编辑子账户信息" :subType="1" :editSub="editsubData"></editSub>
        </div>
    </div>
</template>

<script>
    import editSub from './editSub.vue'
    import {decrypt} from '@/utils/encry.js'
    export default {
        components:{
            editSub
        },
        mounted(){
            const editId = decrypt(this.$route.query.editId)
            this.getUser(editId)
        },
        data(){
            return{
                editsubData:{}
            }
        },
        methods:{
            getUser(id){
                const that = this 
                console.log(id,'id');
                this.$request({
                    url: this.$api.merchantMent.getusers,
                    method: "post",
                    data: {
                        id
                    },
                }).then((res) => {
                    that.editsubData = res.data
                });
            }
        }
    }
</script>

<style lang="scss" scoped>

</style>