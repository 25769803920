<template>
    <div>
        <div class="title main-between">
            <span class="title-left">编辑子账户信息</span>
        </div>
        <div class="add-user main-center">
            <el-form class="add-from" ref="editform" :model="editUserData" label-width="100px" :rules="rules" :hide-required-asterisk="true">
                <el-form-item label="账号" prop="account">
                    <el-input v-model="editUserData.account" placeholder="请填写账号"></el-input>
                </el-form-item>
                <el-form-item label="登录密码" prop="psw">
                    <el-input v-model="editUserData.psw" :show-password="true" placeholder="请填写密码"></el-input>
                </el-form-item>
                <el-form-item label="用户名" prop="marName">
                    <el-input v-model="editUserData.marName" placeholder="请填写用户名"></el-input>
                </el-form-item>
                <el-form-item label="手机号" prop="phone">
                    <el-input v-model.number="editUserData.phone" placeholder="请填写手机号"></el-input>
                </el-form-item>

                <el-form-item label="小程序数量" prop="app_num">
                    <el-input style="width: 75%" v-model.number="editUserData.app_num" :disabled="disablCal(cheAppnum)" placeholder="请填写可创建小程序数量">
                    </el-input>
                    <el-checkbox class="add-checkbox" v-model="cheAppnum">无限制</el-checkbox>
                </el-form-item>
                <el-form-item label="账户有效期" prop="time">
                    <el-date-picker style="width: 75%" v-model="editUserData.time" :disabled="disablCal(cheTime)" placeholder="请选择账户有效时间" value-format="yyyy-MM-dd">
                    </el-date-picker>
                    <el-checkbox class="add-checkbox" v-model="cheTime">永久</el-checkbox>
                </el-form-item>
                <el-form-item>
                    <el-button class="reset-btn" @click="reset">重置</el-button>
                    <el-button type="primary" @click="editUser">编辑</el-button>
                </el-form-item>
            </el-form>
        </div>
    </div>
</template>

<script>
import rules from "@/utils/rules";
import deepCopy from "@/utils/deepCopy";
export default {
    props: {
        editSub: {
            type: Object,
        },
    },
    data() {
        return {
            rules: rules,
            editUserData: {
                id: "",
                marName: "",
                phone: "",
                account: "",
                psw: "",
                app_num: "",
                time: "",
                auth: 1,
            },
            cheTime: false,
            cheAppnum: false,
            storageData: {}, //保存用于重置
            sTime: false,
            sNum: false,
        };
    },
    watch: {
        editSub(newVal, oldVal) {
            const that = this;
            console.log(newVal, "newVal");
            const { id, account, app_num, auth, name, password, phone, time } = newVal;
            let data = { id, account, app_num: "", auth, marName: name, psw: password, phone, time: "" };
            if (app_num == -1 || time == "永久") {
                if (app_num == -1 && time == "永久") {
                    that.cheAppnum = true;
                    that.cheTime = true;
                    that.sTime = true;
                    that.sNum = true;
                } else if (app_num == -1) {
                    that.cheAppnum = true;
                    that.sNum = true;
                    data.time = time;
                } else if (time == "永久") {
                    that.cheTime = true;
                    that.sTime = true;
                    data.app_num = app_num;
                }
            } else {
                data.app_num = app_num;
                data.time = time;
            }
            that.editUserData = deepCopy(data);
            that.storageData = deepCopy(data);
        },
        deep: true,
    },
    methods: {
        // 修改
        editUser() {
            const that = this;
            const { id, marName, phone, account, psw, app_num, time, auth } = this.editUserData;
            let data = {};
            that.$refs.editform.validate((valid) => {
                if (valid) {
                    if (app_num === "" && that.cheAppnum === false) {
                        that.$alert("小程序数量为必填", "", {});
                        return;
                    }
                    if (time == "" && that.cheTime === false) {
                        that.$alert("账户有效期必填", "", {});
                        return;
                    }

                    if (that.cheAppnum === true || that.cheTime === true) {
                        if (that.cheTime === true && that.cheAppnum === true) {
                            data = { id: parseInt(id), name: marName, phone: parseInt(phone), account, psw, app_num: -1, time: "永久", auth };
                        } else if (that.cheTime === true) {
                            data = { id: parseInt(id), name: marName, phone: parseInt(phone), account, psw, app_num, time: "永久", auth };
                        } else if (that.cheAppnum === true) {
                            data = { id: parseInt(id), name: marName, phone: parseInt(phone), account, psw, app_num: -1, time, auth };
                        }
                    } else {
                        data = { id: parseInt(id), name: marName, phone: parseInt(phone), account, psw, app_num, time, auth };
                    }

                    that.$request({
                        url: that.$api.merchantMent.editUser,
                        method: "post",
                        data: data,
                    }).then((res) => {
                        that.$message({
                            message: "修改成功",
                            type: 'success'
                        });
                        that.$router.push("/erchantMent/list");
                    });
                }
            });
        },
        // 重置
        reset() {
            this.editUserData = deepCopy(this.storageData);
            this.cheTime = this.sTime;
            this.cheAppnum = this.sNum;
        },
    },
    computed: {
        disablCal() {
            return function (select) {
                if (select) {
                    return true;
                } else {
                    return false;
                }
            };
        },
    },
};
</script>

<style lang="scss" scoped>
.title {
    height: 50px;
    border-bottom: 1px solid #999999;

    .title-left {
        font-size: 20px;
        font-weight: 600;
    }
}

.add-user {
    margin-top: 70px;

    .add-from {
        width: 50%;

        .reset-btn {
            background-color: #f5f6fa;
            color: #999999;
            margin-right: 24px;
        }

        .add-checkbox {
            margin-left: 40px;
        }
    }
}
</style>
